<template>
  <div>
    <el-card shadow="never" class="m-1" :body-style="{ padding: '0px' }">
      <template #header>
        <el-row type="flex" justify="center" class="mt--5">
          <el-image
            style="width: 350px; height: 100px"
            :src="'/images/logo.png'"
            :fit="'contain'"
          ></el-image>
        </el-row>
        <el-row type="flex" justify="center">
          <small class="small-font"
            >Rua Cel Alcebiades de Miranda, Nº 639. Bairro Boa Vista, Ponta
            Grossa - PR. CEP 84.073-000</small
          >
        </el-row>
        <el-row :gutter="4" type="flex" justify="center">
          <i class="el-icon-message"></i>
          <small class="small-font">donauagronegocios@gmail.com</small>
          <i class="el-icon-phone" style="margin-left: 8px"></i>
          <small class="small-font"> (042) 3227-1744</small>
        </el-row>
        <el-row :gutter="4" type="flex" justify="center"> </el-row>
        <el-row type="flex" justify="space-between" align="center">
          <el-col
            ><el-row type="flex" justify="center"
              ><h4 class="m-0">Relatório de Contas</h4></el-row
            ></el-col
          >
        </el-row>
        <el-row type="flex" justify="space-between" align="center">
          <el-col
            ><el-row type="flex" justify="center"
              ><h5 class="m-0">Fornecedor(es):</h5>
              <h5 class="m-0">{{ getPartners() }}</h5>
            </el-row>
          </el-col>
        </el-row>
      </template>
    </el-card>

    <el-card
      shadow="never"
      class="m-1"
      :body-style="{ padding: '0px' }"
      v-for="(bills, cost) in BillsGroupedByCostCenter"
      :key="cost"
    >
      <h2 class="el-table__subheader_name">{{ cost }}</h2>
      <el-table
        stripe
        :data="bills"
        style="width: 100%; z-index: 0"
        :cell-style="() => 'text-align:center;'"
      >
        <el-table-column
          label="código"
          prop="ref"
          width="100"
        ></el-table-column>
        <el-table-column prop="bill.firm.name" label="empresa">
        </el-table-column>
        <el-table-column prop="bill.partner.name" label="fornecedor">
        </el-table-column>

        <el-table-column
          label="adquirido em"
          :formatter="(r) => formatDate(new Date(r?.bill?.bought_at))"
        ></el-table-column>
        <el-table-column
          label="vencimento"
          :formatter="(r) => formatDate(new Date(r?.expires_at))"
        ></el-table-column>
        <el-table-column label="ordem" prop="order"> </el-table-column>
        <el-table-column
          label="valor"
          prop="amount"
          :formatter="(r) => formatCurrency(r.amount)"
        >
        </el-table-column>
        <el-table-column
          label="valor pago"
          prop="amount_paid"
          :formatter="(r) => formatCurrency(r.amount_paid)"
        >
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :md="15" class="md-15-print">
          <p class="summary summary-black summary-tiny">
            Total do Centro de Custo:
          </p>
        </el-col>
        <el-col :md="3" class="md-3-print"></el-col>
        <el-col :md="3" class="md-3-print">
          <p class="summary summary-tiny align-center">
            {{
              currencyFormatter.format(
                bills?.reduce((t, b) => (t += Number(b?.amount)), 0) || 0
              )
            }}
          </p>
        </el-col>
        <el-col :md="3" class="md-3-print">
          <p class="summary summary-tiny align-center">
            {{
              currencyFormatter.format(
                bills?.reduce((t, b) => (t += Number(b?.amount_paid)), 0) || 0
              )
            }}
          </p>
        </el-col>
      </el-row>
    </el-card>
    <el-row>
      <el-col :md="15" class="md-15-print">
        <p class="summary summary-black">Total Geral:</p>
      </el-col>
      <el-col :md="3" class="md-3-print"></el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary align-center">
          {{
            currencyFormatter.format(
              Bills?.reduce((t, b) => (t += Number(b?.amount)), 0) || 0
            )
          }}
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary align-center">
          {{
            currencyFormatter.format(
              Bills?.reduce((t, b) => (t += Number(b?.amount_paid)), 0) || 0
            )
          }}
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
//import Moment from "moment";
//import { ElNotification } from "element-plus";
export default {
  name: "PackingListReportPage",
  data: () => ({
    reports: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      style: "decimal",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }),
  }),
  computed: {
    Data() {
      return JSON.parse(this.$route?.query?.data || "[]");
    },
    Bills() {
      return this.Data || [];
    },
    BillsGroupedByCostCenter() {
      const groupedBills = this.Bills?.reduce((t, b) => {
        if (t[this.printCostCenterName(b)])
          t[this.printCostCenterName(b)].push(b);
        else t[this.printCostCenterName(b)] = [b];
        return t;
      }, {});

      return this.orderByCostCenter(groupedBills || {});
    },
  },
  methods: {
    orderByCostCenter(groupedBills) {
      return Object.keys(groupedBills || {})
        ?.sort()
        ?.reduce((obj, key) => {
          obj[key] = groupedBills[key];
          return obj;
        }, {});
    },
    printCostCenterName(p) {
      return p?.bill?.cost?.reference || "desconhecido";
    },
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    allPaid(payments) {
      return payments?.every((p) => p.status === "paid");
    },
    getFirstUnpaidPayment(bill) {
      if (!bill) return null;
      let unpaid;
      if (this.allPaid(bill.payments))
        unpaid = bill.payments[bill.payments.length - 1];
      else unpaid = bill?.payments.find((p) => p.status != "paid");
      if (!unpaid)
        unpaid = {
          order: 1,
          total: 1,
          expires_at: bill.expires_at,
          amount: bill.amount,
        };

      return unpaid;
    },
    formatOrders(b) {
      if (b?.payments) {
        const order = b.payments.reduce(
          (t, p) => (t += p.status === "paid" ? 1 : 0),
          0
        );
        return `${order || 0}/${b.quota || 1}`;
      }
      return `${b.order || "1"}/${b.total || "1"}`;
    },
    getAmountPaid(bill) {
      if (bill?.payments) {
        return this.formatCurrency(
          bill.payments.reduce(
            (t, p) => (t += p.status === "paid" ? p.amount : 0),
            0
          )
        );
      }
      return this.formatCurrency(0);
    },
    getPartners() {
      if (this.Data?.length) {
        const partners = this.Data.map((d) => d.partner);
        const names = partners?.reduce((t, f) => {
          t[f?.name] = true;
          return t;
        }, {});

        return Object.keys(names)
          ?.filter((e) => e != "undefined")
          .join(", ");
      }
      return "";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
  },
};
</script>
<style>
@page {
  margin: 0.5cm 0.5cm;
}
body {
  -webkit-print-color-adjust: exact;
}
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 4px !important;
}
.text-red {
  color: red;
}
.m-0 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.m-1 {
  margin: 8px !important;
  max-width: 96%;
}
.text-center {
  text-align: center !important;
}
@media print {
  .el-card__body h2 {
    padding: 4px 0 4px 16px;
  }
  .cell {
    text-align: center;
  }

  table.el-table__body {
    width: 100% !important;
  }
  table .el-table__cell {
    padding: 1px 0px !important;
    line-height: unset !important;
    font-size: 10px !important;
  }
  table.el-table__header {
    width: 100% !important;
  }
  table.el-table__body {
    width: 100% !important;
  }
  div.md-18-print {
    max-width: 74.5% !important;
  }
  div.md-15-print {
    max-width: 62.5% !important;
  }
  div.md-8-print {
    max-width: 33% !important;
  }
  div.md-3-print {
    max-width: 12.5% !important;
  }
  html,
  body {
    height: 98%;
  }
  .avoid-break,
  tr,
  td {
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
    page-break-after: avoid !important;
  }
  .cell {
    word-break: break-word !important;
  }
}
.is-negative {
  color: red !important;
}
.is-positive {
  color: blue !important;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary {
  color: #333;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
  text-align: left;
}
.align-center {
  text-align: center !important;
}
.summary-black {
  color: #909399 !important;
  padding-left: 8px;
}
.summary-tiny {
  font-size: 10px !important;
}
</style>
